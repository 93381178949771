body {
    --black: #000000;
    --ash-black: #222;
    --white: #fafafa;
    --sky: #00ccff;
    --green: #22dddd;
    --blue: #1300ff;
    --dusk: #6600ff;
    --purple: #9900ff;
    --pink: #ff0066;
    --red: #fe0222;
    --orange: #fd7702;
    --yellow: #ffbb00;

    --background: var(--purple);
    --accent: var(--white);

    margin: 0;
    padding: 0;
    background-color: var(--background);
    color: var(--accent);
}

* {
    font-family: "Plaster", sans-serif;
    font-style: normal;
    -webkit-font-smoothing: antialiased;
}

h1,
h2,
h3 {
    font-family: sofia-pro, sans-serif;
    font-weight: 600;
    font-style: normal;
}

h1 {
    font-size: 36px;
    font-weight: 600;
    letter-spacing: -1px;
    line-height: 1.2;
    text-align: center;
    margin: 100px 0 40px;
}

h2 {
    font-weight: 400;
    margin: 50px 0 10px;
}

p {
    margin: 0 0 30px 0;
    font-size: 18px;
}

footer {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 20px;
    background-image: radial-gradient(
            rgba(0, 0, 0, 0) 1px,
            var(--background) 1px
    );
    background-size: 4px 4px;
    backdrop-filter: blur(3px);
    -webkit-backdrop-filter: blur(3px);
    -moz-backdrop-filter: blur(3px);
    font-size: 14px;
    line-height: 14px;
}

footer::before {
    display: block;
    content: "";
    position: absolute;
    top: -1px;
    left: 0;
    right: 0;
    height: 1px;
    background: var(--accent);
    opacity: 0.2;
}

footer svg {
    margin-right: 20px;
}

footer a {
    text-decoration: none;
    color: var(--accent);
}

code {
    font-family: input-mono, monospace;
    font-weight: 400;
    font-style: normal;
}

::-webkit-scrollbar {
    height: 5px;
    width: 5px;
    background: var(--background);
}

::-webkit-scrollbar-thumb {
    background: var(--accent);
    -webkit-border-radius: 1ex;
}

::-webkit-scrollbar-corner {
    background: var(--background);
}

html {
    scroll-snap-type: y mandatory;
}

h1 {
    font-size: 56px;
    font-weight: 600;
    letter-spacing: -3px;
    line-height: 1.2;
    text-align: center;
    margin: 100px 0;
}

h2 {
    margin: 0;
    color: var(--accent);
    left: calc(50% + 130px);
    font-size: 56px;
    font-weight: 600;
    letter-spacing: -3px;
    line-height: 1.2;
    position: absolute;
}

section {
    padding-top: 0vh;
    padding-bottom: 0vh;
    position: relative;
}

img {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
}

.progress {
    position: fixed;
    left: 0;
    right: 0;
    height: 5px;
    background: var(--accent);
    bottom: 100px;
}

.parallax {
    overflow: hidden;
    letter-spacing: -2px;
    line-height: 0.8;
    margin: 0;
    white-space: nowrap;
    display: flex;
    flex-wrap: nowrap;
}

.parallax .scroller {
    -webkit-text-size-adjust: 100%;
    font-feature-settings: "liga", "kern";
    visibility: visible;
    list-style: none;
    box-sizing: inherit;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    font-kerning: auto;
    background-color: transparent;
    position: relative;
    text-decoration: none;
    text-transform: uppercase;
    font-family: 'Druk Wide Super', sans-serif;  /* Using your loaded font */
    font-weight: 900;
    font-style: normal;
    font-stretch: normal;
    font-size: 4.0vw;
    letter-spacing: 0.02em;
    line-height: 0.9em;
    transition: color 0.5s, opacity 0.5s;
    color: transparent;
    -webkit-text-stroke: 3px var(--accent);

    /* Keep these for the parallax effect */
    display: flex;
    white-space: nowrap;
    flex-wrap: nowrap;
}

.parallax span {
    display: block;
    margin-right: 0.02em;
}

.bottom-slider {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background: var(--background);
    padding: 1rem 0;
    z-index: 100;
    overflow: hidden;
    white-space: nowrap;
    display: flex;
    flex-wrap: nowrap;
}

.bottom-slider .scroller {
    -webkit-text-size-adjust: 100%;
    font-feature-settings: "liga", "kern";
    visibility: visible;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    font-kerning: auto;
    background-color: transparent;
    position: relative;
    text-decoration: none;
    text-transform: uppercase;
    font-family: 'Druk Wide Super', sans-serif;
    font-weight: 900;
    font-style: normal;
    font-stretch: normal;
    font-size: 3vw;  /* Smaller size */
    letter-spacing: 0.02em;
    line-height: 0.9em;
    transition: color 0.5s, opacity 0.5s;
    color: #000;
    -webkit-text-stroke: 1px #000;
    display: flex;
    justify-content: center;
    width: 100%;
}

.bottom-slider .scroller span {
    display: block;
    margin-right: 0.02em;
}


@font-face {
    font-family: 'Druk Wide Super'; /* You can name this whatever you want */
    src: local('Druk Wide Super'),
    url('fonts/DrukWide-Super-Web.woff2') format('woff2');
}

.centered-section {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 10vh;
}

.bottom-slider-text {
  -webkit-text-size-adjust: 100%;
  font-feature-settings: "liga", "kern";
  visibility: visible;
  list-style: none;
  box-sizing: inherit;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  font-kerning: auto;
  background-color: transparent;
  text-decoration: none;
  text-transform: uppercase;
  font-family: 'Druk Wide Super', sans-serif;
  font-weight: 900;
  font-style: normal;
  font-stretch: normal;
  font-size: 4.0vw;
  letter-spacing: 0.02em;
  line-height: 0.9em;
  transition: color 0.5s, opacity 0.5s;
  color: transparent;
  -webkit-text-stroke: 3px var(--accent);
  white-space: nowrap;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.bottom-slider-text span {
  display: inline-block;
  margin-right: 0.02em;
}

.bottom-slider-icon {
  margin-left: 10px;
  width: 1em;
  height: 1em;
  pointer-events: none;
  position: relative;
  z-index: 10;
  transform: rotate(-45deg);
  font-size: 4.5rem;
  color: var(--accent);
  transition: all 700ms ease-out;
}

.group:hover .bottom-slider-icon {
  transform: rotate(0deg);
}

@media (max-width: 768px) {
  .parallax .scroller {
    font-size: 6vw;
    -webkit-text-stroke: 1px var(--accent);
    background-color: rgba(0, 0, 0, 0.5);
    padding: 10px;
    border-radius: 5px;
  }
}

/* Mobile styles for bottom slider */
@media (max-width: 768px) {
  .bottom-slider-text {
    font-size: 6vw;
    -webkit-text-stroke: 1px var(--accent);
    background-color: rgba(0, 0, 0, 0.5);
    padding: 10px;
    border-radius: 5px;
  }
}

@media (max-width: 768px) {
  .centered-section {
    height: 15vh;
  }
}

@media (max-width: 768px) {
  .bottom-slider-text {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .bottom-slider-icon {
    margin-left: 0;
    margin-top: 10px;
    width: 1.5em;
    height: 1.5em;
  }
}

@media (max-width: 768px) {
  .bottom-slider-text {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
  }

  .bottom-slider-icon {
    margin-left: 10px;
    margin-top: 0;
    width: 1em;
    height: 1em;
  }
}

@media (max-width: 768px) {
  .centered-section {
    padding: 10px;
  }

  .bottom-slider-text {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    background-color: transparent;
    font-size: 5vw;
    color: var(--accent);
    -webkit-text-stroke: 0;
    white-space: normal;
    text-align: center;
    padding: 0;
  }

  .bottom-slider-text span {
    flex: 1 1 auto;
    margin-right: 10px;
  }

  .bottom-slider-icon {
    flex: 0 0 auto;
    margin-left: 0;
    margin-top: 0;
    width: 1em;
    height: 1em;
  }
}
