@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;600&display=swap');

/* Base Styles */
* {
  box-sizing: border-box;
}

html, body, #root {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}

body {
  font-family: 'Inter', sans-serif;
  background: #f5f5f5;
  color: #333;
  line-height: 1.6;
}

/* Links */
a {
  color: #1976d2;
  text-decoration: none;
  transition: color 0.3s ease;
}

a:hover {
  color: #1565c0;
}

/* Layout Components */
.appRender {
  margin-bottom: 2rem;
}

.Portfolio {
  margin-bottom: 4rem;
}

@media (max-width: 700px) {
  .Portfolio {
    margin-bottom: 2rem;
  }
}

/* Canvas and Animation */
@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

canvas {
  opacity: 0;
  touch-action: none;
  animation: fade-in 5s ease 1s forwards;
}

.Portfolio {
  animation: fade-in 10s;
}

/* Project Cards */
.project-card {
  transition: all 0.2s ease-in-out;
}

.project-thumbnail {
  transition: transform 0.3s ease-in-out;
  background-color: #f5f5f5;
}

.project-content {
  transition: padding 0.2s ease-in-out;
}

@media (max-width: 600px) {
  .project-card {
    flex-direction: column;
  }
  .project-thumbnail {
    width: 100%;
    height: 200px;
  }
}

/* Portfolio iframe styles - Desktop */
@media (min-width: 769px) {
  .Portfolio iframe {
    width: 100%;
    max-width: 650px;
    aspect-ratio: 650 / 460;
    height: auto;
  }

  @supports not (aspect-ratio: 650 / 460) {
    .Portfolio iframe {
      height: 0;
      padding-top: calc(460 / 650 * 100%);
    }
  }
}

/* Portfolio iframe styles - Mobile */
@media (max-width: 768px) {
  .Portfolio iframe {
    width: 85%;
    max-width: 85% !important;
    height: 300px;
  }
}

/* Custom Scrollbar Styles */
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 4px;
}

::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 4px;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* Loading and Transitions */
.fade-enter {
  opacity: 0;
  transform: translateY(20px);
}

.fade-enter-active {
  opacity: 1;
  transform: translateY(0);
  transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
}

.fade-exit {
  opacity: 1;
  transform: translateY(0);
}

.fade-exit-active {
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
}

/* Responsive Typography */
@media (max-width: 768px) {
  h1 {
    font-size: 1.8rem;
  }
  h2 {
    font-size: 1.5rem;
  }
  h3 {
    font-size: 1.2rem;
  }
  p {
    font-size: 0.95rem;
  }
}

/* Print Styles */
@media print {
  .Portfolio iframe, 
  .project-thumbnail {
    break-inside: avoid;
  }
  .no-print {
    display: none;
  }
}

/* High Contrast Mode */
@media (prefers-contrast: high) {
  body {
    background: white;
    color: black;
  }
  a {
    color: #0000EE;
  }
  a:visited {
    color: #551A8B;
  }
}

/* Reduced Motion */
@media (prefers-reduced-motion: reduce) {
  * {
    animation: none !important;
    transition: none !important;
  }
}

/* Focus Styles */
:focus {
  outline: 2px solid #1976d2;
  outline-offset: 2px;
}

:focus:not(:focus-visible) {
  outline: none;
}

:focus-visible {
  outline: 2px solid #1976d2;
  outline-offset: 2px;
}